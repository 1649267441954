<template>
  <div class="CreatorExploreCard" :style="`--cover:url(${url_encode(creator.cover)});--zoom:${cardStyle.zoom};--top:${cardStyle.top};`">
    <div class="Cover">
      <span class="overblur"></span>
      <div class="Label">
        <div class="Left">
          <p class="Name">{{ creator.name }}</p>
          <p class="User">@{{ creator.user }}</p>
        </div>
        <div class="Right" v-if="videoCover">
          <CircleButton class="VideoCoverButton" icon="play" size="45px" background="transparent" active="button" @click="MediaView(videoCover)" />
        </div>
      </div>
      <div class="Wrapper" @click="UserInfo"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["creator"],
  methods: {
    UserInfo: function() {
      this.view({
        title: `@${this.creator.user}`,
        component: () => import("./CreatorExploreInfo.vue"),
        class: "menu bottom CreatorExploreInfo",
        animation: "bottomIn",
        closeOnSwipe: "down",
        closeOutside: true,
        clean: true,
        header: false,
        creator: this.creator,
      });
    },
  },
  computed: {
    videoCover: function() {
      return this.creator.videoCover && this.creator.videoCover.src && this.creator.videoCover;
    },
    cardStyle: function() {
      const style = this.creator.style && this.creator.style.cover ? this.creator.style.cover : { zoom: "cover", top: "50%" };
      return style;
    },
  },
};
</script>

<style lang="scss">
.CreatorExploreCard {
  position: relative;
  min-height: 400px;
  margin: 0;

  .Wrapper {
    @include Absolute();
    z-index: 0;
  }

  .Cover {
    position: relative;
    border-radius: $mpadding * 1.5;
    width: 100%;
    height: calc(400px - #{$mpadding * 1});
    overflow: hidden;
    @include transition(0.25s);
    cursor: pointer;
  }

  .Cover:before,
  .Cover:after {
    content: "";
    @include Absolute();
    width: 100%;
    height: 100%;
    border-radius: $mpadding * 1.5;
    @include transition(0.25s);
    @include BgImage(var(--cover));
  }

  .overblur {
    @include Absolute();
    width: 100%;
    height: 100%;
    backdrop-filter: blur(20px);
  }

  .Cover:after {
    @include BgImage(var(--cover));
    background-size: var(--zoom);
    background-position: top calc(0px + var(--top)) center;
    background-color: #000;
    pointer-events: none;
  }

  .Cover:active {
    transform: scale(0.95);
  }

  .Label {
    position: absolute;
    padding: $mpadding;
    color: #fff;
    @include textGradient();
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    z-index: 2;
    pointer-events: none;
  }

  .Label .Right {
    display: flex;
    align-items: flex-end;
  }

  .Name {
    font-size: 150%;
    font-family: $third_font_bold;
  }

  .VideoCoverButton.CircleButton {
    border: solid 1px #fff;
    border-radius: 50%;
    pointer-events: all;
  }

  .VideoCoverButton.CircleButton .CircleButtonIcon {
    font-size: 150%;
  }
}
</style>
